exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-dorty-tsx": () => import("./../../../src/pages/dorty.tsx" /* webpackChunkName: "component---src-pages-dorty-tsx" */),
  "component---src-pages-formular-odeslan-tsx": () => import("./../../../src/pages/formular-odeslan.tsx" /* webpackChunkName: "component---src-pages-formular-odeslan-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-nabidka-tsx": () => import("./../../../src/pages/nabidka.tsx" /* webpackChunkName: "component---src-pages-nabidka-tsx" */),
  "component---src-pages-o-mne-tsx": () => import("./../../../src/pages/o-mne.tsx" /* webpackChunkName: "component---src-pages-o-mne-tsx" */),
  "component---src-pages-zakusky-tsx": () => import("./../../../src/pages/zakusky.tsx" /* webpackChunkName: "component---src-pages-zakusky-tsx" */)
}

